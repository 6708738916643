<template>
  <div class="realEstatePanel">
    <div class="mt-2" v-if="loaded">
      <validation-observer ref="refFormObserver">
        <!-- Header: Personal Info -->
        <div class="d-flex">
          <feather-icon icon="PackageIcon" size="19"/>
          <h4 class="mb-0 ml-50">
            {{ msg('New Building') }}
          </h4>
        </div>


        <!-- Form: Personal Info Form -->
        <b-row class="mt-1">
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Company')" label-for="building-company">
              <b-form-input id="realEstate-sell-price" v-model="building.company"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Address')" label-for="building-company">
              <b-form-input id="realEstate-sell-price" v-model="building.address"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Zip')" label-for="building-company">
              <b-form-input id="realEstate-sell-price" v-model="building.zip"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Location')" label-for="building-company">
              <b-form-input id="realEstate-sell-price" v-model="building.location"
              />
            </b-form-group>
          </b-col>


          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('GST')" label-for="building-gst">
              <validation-provider #default="{ errors }" :name="msg('GST')" rules="required">
                <b-form-input id="building-gst" v-model="building.gst"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('KG')" label-for="building-gst">
              <validation-provider #default="{ errors }" :name="msg('KG')" rules="required">
                <b-form-input id="building-gst" v-model="building.kg"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Usage')" label-for="building-company">
              <b-form-input id="realEstate-sell-price" v-model="building.usage"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Total space')" label-for="building-company">
              <b-form-input id="realEstate-sell-price" v-model="building.space"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Owner')" label-for="realEstate-sell-price">
              <validation-provider #default="{ errors }" :name="msg('Owner')" rules="required">
                <client-picker v-model="building.ownerId" is-owner/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Branch')" label-for="product-branch">
              <validation-provider #default="{ errors }" :name="msg('Branch')" rules="required">
                <v-select
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    v-model="building.buid"
                    :options="allBranches"
                    class="w-100"
                    label="name"
                    :reduce="(branch) => branch.id"
                    :clearable="false"

                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Offer template')" label-for="product-branch">
              <validation-provider #default="{ errors }" :name="msg('Offer template')" rules="required">
                <v-select
                    multiple
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    v-model="building.offerTemplateIds"
                    :options="allTemplates"
                    class="w-100"
                    label="name"
                    :reduce="(branch) => branch.id"
                    :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Status')" label-for="product-branch">
              <validation-provider #default="{ errors }" :name="msg('Status')" rules="required">
                <v-select
                    style="min-width: 300px;text-align: center"
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    v-model="building.status"
                    label="text"
                    :options="allStatus"
                    :reduce="(status) => status.value"
                    class="w-100"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('City tax')" label-for="product-branch">
              <validation-provider #default="{ errors }" :name="msg('city tax')" rules="required">
                <b-form-input id="realEstate-name" v-model="building.cityTax" type="number" min="0"
                              step="0.1" :state="errors.length > 0 ? false:null"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Vat') + ' (Example: 9.8)'" label-for="product-branch">
              <validation-provider #default="{ errors }" :name="msg('Vat')" rules="required">
                <b-form-input id="realEstate-name" v-model="building.vat" type="number" min="0"
                              step="0.1" :state="errors.length > 0 ? false:null"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="2">
            <b-form-group :label="msg('Hostaway ID')" label-for="businessunit-website">
                <b-form-input v-model="building.hostawayId"/>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <b-form-group :label="msg('Hostaway SECRET')" label-for="businessunit-externalLink">
              <validation-provider #default="{ errors }" :name="msg('Hostaway SECRET')" rules="required">

                <b-form-input v-model="building.hostawaySecret"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="4">
            <b-form-group :label="msg('Copy Hostaway Credentials from other building')" label-for="product-branch">
              <v-select
                  placeholder="Copy from Building"
                  :dir="isRTL ? 'rtl' : 'ltr'"
                  v-model="buildingHostaway"
                  :options="buildings"
                  class="w-100"
                  label="address"
                  :reduce="(building) => building"
                  @option:selected="copyCredentials(buildingHostaway)"

              />
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </div>
    <div v-else-if="this.error != null">
      <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i>
        <strong>{{ error }}</strong>
      </b-alert>
    </div>
    <div v-else>
      <div style="min-height: 300px;text-align: center">
        <div style="margin-top: 20%">
          <b-spinner label="Spinning"/>
          {{ msg('loading') }}
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex'
import ClientPicker from "@/components/components/picker/clientPicker.vue";

const buildingFilter = () => ({
  like: {},
  eq: {},
  lte: {},
  gte: {},
  in: {}
})
export default {
  name: 'buildingPanel',
  components: {ClientPicker},
  data() {
    return {
      loaded: false,
      templateList: [],
      buildingFilter: buildingFilter(),
      buildings: [],
      buildingHostaway:null,
      error: null,
      buildingId: 0,
      building: {
        company: '',
      },
      //Listeners
      listeners: {
        submit: () => {
        }
      }
    }
  },
  props: {
    id: Number,
  },
  watch: {
    id: function () { // watch it
      this.onLoad()
      this.error = null
    }
  },

  computed: {
    ...mapGetters('data', ['getAllBranches']),
    ...mapGetters('building', ['fileTags', 'allStatuse']),
    allTemplates() {
      return this.templateList
          .map(bank => ({
            id: bank.id,
            name: (bank.name)
          }))
    },
    allBranches() {
      return Object.values(this.getAllBranches)
    },
    allStatus() {
      return this.allStatuse.map(status => ({
        value: status.value,
        text: status.text.toUpperCase()
      }))
    },
  },
  created() {
    let $this = this;
    let filterNew = {
      search: '',
      like: {name: "", shortDescription: ""},
      eq: {type: "", buid: this.building.buid, template: true},
      lte: {},
      gte: {}
    }
    Promise.all([this._loadTemplateList({amount: 9999, filter: filterNew})
        .then(ans => {
          $this.templateList = ans["Templates"];

        }),
      this.getAllBuildings({
        page: 1,
        amount: 9999,
        filter: $this.buildingFilter,
        sortcolumn: 'updatedDate',
        sortby: 'desc',
      }).then($this.onBuildingsRetrieve, (error) => $this.error = error)

    ])

    this.onLoad()
    this.$root.$on('crm::submitBuilding', (this.listeners.submit = () => {
      this.submit()
    }))
  },
  beforeDestroy() {
    this.$root.$off('crm::submitBuilding', this.listeners.submit)
  },
  methods: {
    ...mapActions('contractTemplate', {_loadTemplateList: 'getAllTemplates'}),
    ...mapActions('building', ['getAllBuildings']),

    ...mapActions('building', {
      _register: 'addBuilding',
    }),
    onLoad() {
      this.loaded = true
    }
    ,
    createBuilding() {
      let $this = this
      this.$refs['refFormObserver'].validate().then(valid => {
        if (!valid)
          return;
        $this._register(this.building)
            .then(
                (id) => {

                  $this.buildingId = id
                  $this.$emit('submitted', {status: true, server: id, id})
                  $this.$router.push('/building/edit/' + id)

                },
                (a) => $this.$emit('submitted', {
                  status: false,
                  server: a
                })
            )
      })
    },

    validate(field) {
      return this.$v.building[field] != null && this.$v.building[field].$error === true
    }
    ,
    submit() {
      return this.createBuilding()
    },
    copyCredentials(building) {
      this.building.hostawayId = building.hostawayId
      this.building.hostawaySecret = building.hostawaySecret
    }
    , onBuildingsRetrieve(data) {
      this.buildings = data["Buildings"].filter(building=>building.hostawayId!=null && building.hostawaySecret!=null);
    },
  },

}
</script>


